import React, { type FC } from 'react';
import { defineMessages, FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import type { GlyphProps } from '@atlaskit/icon';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import LockIcon from '@atlaskit/icon/core/lock-locked';
import UnlockIcon from '@atlaskit/icon/core/lock-unlocked';
import type { NewCoreIconProps } from '@atlaskit/icon/base-new';

import { WorldGreenDotIcon } from '../svg/WorldGreenDotIcon-svg';

export const i18n = defineMessages({
	share: {
		id: 'share-and-restrict-dialog.controlled-share-button.share',
		defaultMessage: 'Share',
		description:
			'Button text for the Share and Restrict button, which opens the Share and Restrict dialog',
	},
});

export type ControlledShareButtonProps = {
	icon: ControlledShareButtonIcon;
	onClick: () => void;
	tooltipMessage: MessageDescriptor;
	isDisabled: boolean;
};

export type ControlledShareButtonIcon = 'open' | 'restricted' | 'public' | 'error';

const ICON_PROP_TO_ICON_COMPONENT: {
	[key in ControlledShareButtonIcon]:
		| typeof UnlockIcon
		| typeof LockIcon
		| typeof WorldGreenDotIcon
		| typeof ErrorIcon;
} = {
	open: UnlockIcon,
	restricted: LockIcon,
	public: WorldGreenDotIcon,
	error: ErrorIcon,
};

const makeIconProps = (icon: ControlledShareButtonIcon): Partial<GlyphProps | NewCoreIconProps> => {
	switch (icon) {
		case 'error':
			return { color: token('color.icon.danger') };
		case 'restricted':
			return { color: token('color.icon.danger') };
		case 'public':
			return { secondaryColor: token('color.icon.accent.green') };
		default:
			return { color: token('color.icon.subtle') };
	}
};

export const ControlledShareButton: FC<ControlledShareButtonProps> = ({
	icon,
	onClick,
	tooltipMessage,
	isDisabled,
	children,
}) => {
	const { formatMessage } = useIntl();
	const Icon = ICON_PROP_TO_ICON_COMPONENT[icon];
	const iconProps: GlyphProps = {
		label: '',
		...makeIconProps(icon),
	};

	return (
		<Tooltip
			content={formatMessage(tooltipMessage)}
			position="bottom"
			canAppear={() => !children}
			hideTooltipOnMouseDown
			ignoreTooltipPointerEvents
			delay={100}
		>
			{(tooltipProps) => (
				<Button
					{...tooltipProps}
					appearance="subtle"
					iconBefore={(parentProps) => <Icon {...parentProps} {...iconProps} />}
					onClick={onClick}
					aria-label={`${formatMessage(i18n.share)}, ${formatMessage(tooltipMessage)}`}
					isDisabled={isDisabled}
				>
					{children}
					<FormattedMessage {...i18n.share} />
				</Button>
			)}
		</Tooltip>
	);
};
