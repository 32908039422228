import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { ShareRestrictAndCopyButtonPlaceholder } from './v2/shareRestrictAndCopyButton/ShareRestrictAndCopyButtonPlaceholder';
import type { ShareRestrictAndCopyButtonProps } from './v2/shareRestrictAndCopyButton/ShareRestrictAndCopyButton';
import { SHARE_BUTTON_PAGE_SEGMENT_LOAD } from './perf.config';

export { getClosestAncestorWithViewRestrictions } from './v2/controller/queries/dataToQueriesState';
export {
	type ContentType,
	type ContentSubType,
} from './v2/shareAndRestrictButton/ShareAndRestrictButton';

const ShareAndRestrictButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ShareRestrictAndCopyButton" */ './v2/shareRestrictAndCopyButton/ShareRestrictAndCopyButton'
			)
		).ShareRestrictAndCopyButton,
	loading: ShareRestrictAndCopyButtonPlaceholder,
});

export type ShareAndRestrictButtonProps = ShareRestrictAndCopyButtonProps;

export const ShareAndRestrictButton = (props: Omit<ShareAndRestrictButtonProps, 'flags'>) => (
	<>
		<PageSegmentLoadStart
			metric={SHARE_BUTTON_PAGE_SEGMENT_LOAD}
			isCustomStart
			key={props.contentId}
		/>
		<ShareAndRestrictButtonLoader {...props} />
	</>
);
